<template>
  <div>
    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="note">
          <p>
            Не делайте здесь заказ на запчасти если в ближайшее время
            собираетесь делать заявку по ремонтам
          </p>
          <p>
            Запчасти для не гарантийных ремонтов можно включить в
            <router-link to="/repairs/repairs-list"
              >заявку по ремонтам</router-link
            >
          </p>
        </div>
        <div>
          <Selected
            :key="formKey"
            title="кликните для выбора инструмента (если нужна схема)"
            :setChangeInstr="true"
            @updateCodInstr="setCodInstr"
          />
        </div>
        <div>
          <SelectZapForRepair
            :dataZap="dataZap"
            :oldzapvisible="false"
            :newZapTitle="``"
            :cenaVisible="true"
            :visible="true"
            :getParams="{ onlyInStock: true }"
            :title="'поиск запчастей по артикулу, наименованию или коду'"
            :prompt="true"
          />
        </div>
        <div v-if="ZAP && ZAP.length"></div>
        <div v-if="save_zap" class="">
          <div class="text-center note">сохраненные запчасти</div>
          <div>
            <table class="table table-sm table-responsive">
              <tr>
                <th></th>
                <th></th>
                <th class="text-center">наименование</th>
                <th class="text-center">артикул</th>
                <th class="text-center">код</th>
                <th class="text-center">остаток</th>
                <th class="text-center">шт</th>
                <th class="text-center">цена</th>
              </tr>
              <tr v-for="(item, idx) in save_zap" :key="idx + 'save'">
                <td @click="deleteZap(item)" class="click">
                  <i class="bi bi-trash"></i>
                </td>
                <td>{{ idx + 1 }}</td>
                <td>{{ item.name }}</td>
                <td class="text-center">{{ item.art }}</td>
                <td class="text-center">{{ item.cod }}</td>
                <td class="text-center">{{ item.ostatok }}</td>
                <td class="text-center">{{ item.pcs }}</td>
                <td class="text-center">{{ item.cena }}</td>
              </tr>
              <tr>
                <td colspan="4"></td>
                <td colspan="2" class="text-right">итого:</td>
                <td>{{ summ }}</td>
                <td></td>
              </tr>
            </table>
          </div>

          <div class="wrapper">
            <textarea
              class="form-control form-control-sm input-form shadow-none"
              rows="3"
              placeholder="комментарий к заказу"
              v-model="comment"
              @input="g"
            ></textarea>
          </div>
        </div>
        <div>
          <button
            class="btn btn-sm btn-outline-primary shadow-none"
            @click="sendOrderZap"
            v-if="save_zap && this.ZAP && !this.ZAP.length"
            :disabled="send_order_button_disabled"
          >
            отправить заказ
          </button>
          <button
            v-if="this.ZAP && this.ZAP.length"
            class="btn btn-sm btn-outline-primary shadow-none"
            @click="saveOrderZap"
          >
            сохранить
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div v-if="!no_photo_sheme && shemeFotoUrl" class="sheme-wrapper">
          <div class="sheme" v-for="url in shemeFotoUrl.name" :key="url.id">
            <h6 class="text-center">
              {{ url.name }}
              <a href="#" @click.prevent="getVisibleSheme(url.name)"
                >показать/скрыть - схему</a
              >
            </h6>
            <div v-if="visibleSheme === url.name">
              <div><a :href="url.download_url" target="blank">скачать</a></div>
              <InnerImageZoom
                v-for="i in shemeFotoUrl[url.name]"
                :key="i.id"
                :src="i.url"
                :zoomSrc="i.url"
              />
            </div>
          </div>
        </div>
        <div v-else class="text-center card" style="width: 50%;margin:auto;">
          <div class="card-body">
            <p>не удалось найти схему</p>
            <p>
              попробуйте поискать
              <a
                href="http://okzip.ru/index.php?resources/categories/СХЕМЫ-КАЛИБР.2/"
                target="blank"
                >тут</a
              >
            </p>
            <p>или обратитесь к своему менеджеру</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import Selected from "@/components/Selected.vue";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
import SelectZapForRepair from "@/components/SelectZapForRepair.vue";
import { mapGetters, mapMutations } from "vuex";
import { HTTPPOST } from "@/api";
export default {
  name: "AddNewOrderZap",
  computed: {
    ...mapGetters(["ZAP"]),
    disabled: function() {
      if (this.save_zap) {
        return true;
      } else {
        return false;
      }
    }
  },
  components: {
    Selected,
    InnerImageZoom,
    SelectZapForRepair
  },
  data: () => ({
    no_photo_sheme: false,
    shemeFotoUrl: null,
    visibleSheme: false,
    formKey: 1,
    dataZap: {
      data: null,
      loading: true,
      placeholder: ""
    },
    zap: [],
    save_zap: null,
    comment: "",
    send_order_button_disabled: false
  }),
  mounted() {
    this.get_save_data();
  },
  methods: {
    ...mapMutations(["zapNull"]),
    setCodInstr(cod) {
      HTTPGET({
        url: "get_foto_sheme/",
        params: { cod }
      })
        .then(response => (this.shemeFotoUrl = response.data))
        .then(response =>
          response
            ? (this.no_photo_sheme = false)
            : (this.no_photo_sheme = true)
        );
    },
    getVisibleSheme(name) {
      if (this.visibleSheme === name) {
        this.visibleSheme = false;
      } else {
        this.visibleSheme = name;
      }
    },
    sendOrderZap() {
      this.send_order_button_disabled = true;
      HTTPPOST({
        url: "/order_zap/add_new_order_zap.php",
        data: {
          send_order: true,
          comment: this.comment
        }
      }).then(() => this.$router.push("/zap/order-zap-list?type=inwork"));
    },
    saveOrderZap() {
      HTTPPOST({
        url: "/order_zap/add_new_order_zap.php",
        data: {
          data: this.ZAP,
          comment: this.comment,
          save: true,
          add: this.disabled,
          summ: null
        }
      })
        .then(() => this.get_save_data())
        .then(() => this.zapNull());
    },
    get_save_data() {
      HTTPGET({
        url: "/order_zap/get_save_order.php"
      }).then(response => {
        this.save_zap = response.data.order;
        if (response.data) {
          this.comment = response.data.order
            ? response.data.order[0].comment
            : "";
          this.summ = response.data.summ;
        }
      });
    },
    deleteZap(item) {
      if (confirm(`удалить ${item.name}?`)) {
        HTTPGET({
          url: "/order_zap/del_zap_from_save_zap.php",
          params: {
            id: item.id
          }
        }).then(() => this.get_save_data());
      }
    },
    g() {}
  }
};
</script>
<style lang="scss" scoped>
.sheme {
  width: 100%;
}
.sheme-wrapper {
  overflow: auto;
  height: 80vh;
}
.note {
  font-size: 0.9em;
  font-weight: bold;
}
.wrapper {
  margin-bottom: 10px;
}
table {
  font-size: 0.7em;
}
td,
th {
  vertical-align: middle;
}

.bi-trash {
  color: red;
}
.click {
  cursor: pointer;
}
</style>
